import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { appWithTranslation } from "next-i18next";
import { AppContext, AppProps } from "next/app";
import { NextRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IEnvironment } from "../src/api/types/environment";
import SnackbarProvider from "../src/components/SnackbarProvider";
import { wrapper } from "../src/store";
import { authActions } from "../src/store/modules/auth";
import { environmentActions } from "../src/store/modules/enviroment";
import { getCookie, setCookie } from "../src/utils/cookies";
import { initializeGoogleAnalytics, initGa } from "../src/utils/ga";
import { initializeHotjar } from "../src/utils/hotjar";
import theme from "../src/utils/theme";
import "@/styles/globals.css";

interface IRootAppProps {
  environment?: IEnvironment;
}

function RootApp({
  Component,
  pageProps,
  router,
  environment,
}: AppProps & IRootAppProps) {
  return (
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider>
        <CssBaseline />
        <Global router={router} environment={environment} />
        <Component {...pageProps} />
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}

async function getEnvironment() {
  const environment = {
    env: process.env.ENV,
    version: process.env.VERSION,
    service: process.env.SERVICE,
    baseUrl: process.env.APP_BASE_URL,
    sentry: {
      dsn: process.env.SENTRY_DSN,
    },
    datadog: {
      applicationId: process.env.DATADOG_APPLICATION_ID,
      clientToken: process.env.DATADOG_CLIENT_TOKEN,
      site: process.env.DATADOG_SITE,
    },
    googleAnalytics: {
      trackingId: process.env.GOOGLE_ANALYTICS_TRACKING_ID,
    },
    hotjar: {
      id: process.env.HOTJAR_ID,
    },
  };
  return environment;
}

RootApp.getInitialProps = async ({
  ctx: { req, res, pathname },
  router,
}: AppContext): Promise<IRootAppProps> => {
  // Don't preload environment if generating on build
  if (router.asPath === "/500") {
    return {};
  }

  if (req && res) {
    res.locals ??= {};

    res.locals.route = pathname;

    const environment = await getEnvironment();

    initializeReporting(environment);

    return {
      environment,
    };
  }

  return {};
};

interface IGlobalProps {
  router: NextRouter;
  environment?: IEnvironment;
}

function Global({ router, environment }: IGlobalProps) {
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.dev ??= {};

    dispatch(authActions.whoami());

    initGa(router);

    const jssStyles = document.querySelector("#jss-server-side");
    jssStyles?.parentElement?.removeChild(jssStyles);

    const locale = getCookie("NEXT_LOCALE");

    if (!locale) {
      const preferredLanguage =
        (navigator.languages?.length
          ? navigator.languages
              .find((language) =>
                router.locales?.includes(language.split("-")[0])
              )
              ?.split("-")[0]
          : navigator.language?.split("-")[0]) || "";

      const language = router.locales?.includes(preferredLanguage)
        ? preferredLanguage
        : router.defaultLocale;

      setCookie("NEXT_LOCALE", language!, 365);

      router.push(router, undefined, {
        locale: language,
      });
    } else if (router.locale !== locale) {
      router.push(router, undefined, {
        locale,
      });
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      return;
    }

    // Initialize environment/reporting
    if (environment) {
      dispatch(environmentActions.setEnvironment(environment));
      initializeReporting(environment);
      setInitialized(true);
    } else {
      (async () => {
        const environment = await getEnvironment();

        dispatch(environmentActions.setEnvironment(environment));
        initializeReporting(environment);
        setInitialized(true);
      })();
    }
  }, [environment, initialized]);

  return null;
}

function initializeReporting(environment: IEnvironment) {
  if (typeof window !== "undefined") {
    console.log(`Propty Vote ${environment.version || "no-version"}`);

    if (window.disableTracking) {
      if (environment.googleAnalytics.trackingId) {
        // @ts-ignore
        window[`ga-disable-${environment.googleAnalytics.trackingId}`] = true;
      }

      return;
    }

    // Can only be ran in browser
    if (environment.googleAnalytics.trackingId) {
      initializeGoogleAnalytics(
        environment.googleAnalytics.trackingId,
        window.location.pathname
      );
    }

    if (environment.hotjar.id) {
      initializeHotjar(environment.hotjar.id);
    }
  }
}

export default appWithTranslation(
  wrapper.withRedux(RootApp) as React.FunctionComponent
);
